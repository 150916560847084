import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import PortfolioLink from './PortfolioLink'
import style from './style.module.less'

function GradLink({ post }) {
	return (
		<div className={style.item}>
			<Link to={post.frontmatter.path} style={{ textDecoration: 'none' }}>
				<Img fluid={post.frontmatter.cover.childImageSharp.fluid} />
			</Link>
			<div className={style.container}>
				<Link to={post.frontmatter.path}>
					<div className={style.name}>{post.frontmatter.name}</div>
					<div className={style.spec}>{post.frontmatter.field}</div>
				</Link>
				{post.frontmatter.portfolio === null ? null : (
					<PortfolioLink portfolio={post.frontmatter.portfolio} />
				)}
			</div>
		</div>
	)
}

GradLink.propTypes = {
	post: PropTypes.any,
}

export default GradLink
