import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import LinkIcon from '../../../../../static/assets/icons/link-icon.svg'
import style from './style.module.less'

export default class PortfolioLink extends PureComponent {
	render() {
		const { portfolio } = this.props
		return (
			<a href={portfolio} target="_blank" rel="noopener noreferrer">
				<img src={LinkIcon} className={style.desktopNavIcon} />
			</a>
		)
	}
}

PortfolioLink.propTypes = {
	portfolio: PropTypes.string
}
