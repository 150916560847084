import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import GradLink from './GradLink'
// import Search from '../Search'
import style from './style.module.less'

class GraduatesList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			queryText: '',
			fieldsFilterList: [],
			filtersApplied: [],
			mobileFilterDialogOpen: false,
		}
		this.buildfieldsFilterList = this.buildfieldsFilterList.bind(this)
		this.toggleFilters = this.toggleFilters.bind(this)
		this.toggleMobileFilterDialog = this.toggleMobileFilterDialog.bind(this)
	}

	componentDidMount() {
		this.buildfieldsFilterList()
	}

	buildfieldsFilterList() {
		let uniqueFieldsList = []
		this.props.data.allMarkdownRemark.edges.forEach(edge => {
			const stringToList = edge.node.frontmatter.field.trim().split(', ')
			stringToList.forEach(individualField => {
				uniqueFieldsList.includes(individualField)
					? null
					: uniqueFieldsList.push(individualField)
			})
		})
		uniqueFieldsList.indexOf('a') > -1
			? uniqueFieldsList.splice(uniqueFieldsList.indexOf('a'), 1)
			: uniqueFieldsList.indexOf('b') > -1
				? uniqueFieldsList.splice(uniqueFieldsList.indexOf('b'), 1)
				: uniqueFieldsList.sort()[0] === ''
					? uniqueFieldsList.sort().shift()
					: uniqueFieldsList

		this.setState({ fieldsFilterList: uniqueFieldsList.sort() })
	}

	searchGraduates(query) {
		this.setState({ queryText: query })
	}

	toggleFilters(e, filterLabel) {
		const index = this.state.filtersApplied.indexOf(filterLabel)

		let updatedList = this.state.filtersApplied

		index > -1
			? updatedList.splice(index, 1)
			: updatedList.push(filterLabel)

		this.setState({ filtersApplied: updatedList })
	}

	toggleMobileFilterDialog() {
		const previousValue = this.state.mobileFilterDialogOpen
		this.setState({
			mobileFilterDialogOpen: !previousValue,
		})
	}

	clearFilterList() {
		this.setState({ filtersApplied: [] })
	}

	render() {
		let { edges: posts } = this.props.data.allMarkdownRemark
		posts = posts
			.filter(edge => !!edge.node.frontmatter.name)
			.filter(eachItem => {
				return eachItem.node.frontmatter.name
					.toLowerCase()
					.includes(this.state.queryText.toLowerCase())
			})
			.filter(eachItem => {
				let letItemThrough = false
				for (var i = 0; i < this.state.filtersApplied.length; i++) {
					if (
						eachItem.node.frontmatter.field.includes(
							this.state.filtersApplied[i],
						)
					) {
						return (letItemThrough = true)
					}
				} // Man, this is some ugly code right here

				if (letItemThrough || this.state.filtersApplied.length === 0) {
					return eachItem
				} else {
					return null
				}
			}) // filters the total list by interest area
			.map(edge => <GradLink key={edge.node.id} post={edge.node} />)

		return (
			<div>
				<h1 className={style.meetGrads}>Meet the Graduates</h1>
				<div className={style.inputContainer}>
					<input
						type="text"
						value={this.state.query}
						aria-label="Search Graduates"
						onChange={e => this.searchGraduates(e.target.value)}
						placeholder={'Search Graduates'}
					/>
				</div>
				<ul className={style.GraduateFiltersList}>
					{this.state.fieldsFilterList.map((label, index) => {
						return (
							<li
								style={
									this.state.filtersApplied.includes(label)
										? {
											fontWeight: 'bold',
											border: '1px solid #595959',
										  }
										: {}
								}
								key={index}
								className={style.GraduateFiltersListItem}
								onClick={e => this.toggleFilters(e, label)}
							>
								{label}
							</li>
						)
					})}
					<button
						className={style.ClearAllButton}
						onClick={e => this.clearFilterList(e)}
					>
						Clear All
					</button>
				</ul>
				<div className={style.graduatesListContainer}>{posts}</div>
				<button
					className={style.GraduatesMobileFilterButton}
					onClick={e => this.toggleMobileFilterDialog(e)}
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 32 32"
						width="24"
						height="24"
					>
						<line
							fill="none"
							stroke="#000000"
							strokeWidth="2.5"
							strokeLinecap="round"
							strokeMiterlimit="10"
							x1="4.5"
							y1="24"
							x2="27.5"
							y2="24"
						/>
						<circle
							fill="#FFFFFF"
							stroke="#000000"
							strokeWidth="2.5"
							strokeLinecap="round"
							strokeMiterlimit="10"
							cx="22"
							cy="24"
							r="3"
						/>
						<line
							fill="none"
							stroke="#000000"
							strokeWidth="2.5"
							strokeLinecap="round"
							strokeMiterlimit="10"
							x1="4.5"
							y1="16"
							x2="27.5"
							y2="16"
						/>
						<circle
							fill="#FFFFFF"
							stroke="#000000"
							strokeWidth="2.5"
							strokeLinecap="round"
							strokeMiterlimit="10"
							cx="10"
							cy="16"
							r="3"
						/>
						<line
							fill="none"
							stroke="#000000"
							strokeWidth="2.5"
							strokeLinecap="round"
							strokeMiterlimit="10"
							x1="4.5"
							y1="8"
							x2="27.5"
							y2="8"
						/>
						<circle
							fill="#FFFFFF"
							stroke="#000000"
							strokeWidth="2.5"
							strokeLinecap="round"
							strokeMiterlimit="10"
							cx="22"
							cy="8"
							r="3"
						/>
					</svg>
					<p>Filter</p>
				</button>
				<div
					className={style.GraduatesMobileFilterDialog}
					style={
						this.state.mobileFilterDialogOpen
							? {
								visibility: 'visible',
								transform: 'translateY(0)',
							  }
							: {
								visibility: 'hidden',
								transform: 'translateY(100%)',
							  }
					}
				>
					<div className={style.GraduatesMobileFilterDialogTitle}>
						<p style={{ opacity: '0' }}>Nothing</p>
						<div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="16"
								viewBox="0 0 16 16"
							>
								<polyline
									fill="#FFFFFF"
									stroke="#000000"
									strokeMiterlimit="10"
									strokeWidth="1.5"
									points="13.5,4.25 8,9.75 2.5,4.25 "
								/>
							</svg>
							<p onClick={e => this.toggleMobileFilterDialog(e)}>
								Filter
							</p>
						</div>
						<button onClick={e => this.clearFilterList(e)}>
							Clear All
						</button>
					</div>
					<ul className={style.GraduatesMobileFilterList}>
						{this.state.fieldsFilterList.map((label, index) => {
							return (
								<li
									style={
										this.state.filtersApplied.includes(
											label,
										)
											? { fontWeight: 'bold' }
											: {}
									}
									key={index}
									className={
										style.GraduatesMobileFilterListItem
									}
									onClick={e => this.toggleFilters(e, label)}
								>
									<p>{label}</p>
									{this.state.filtersApplied.includes(
										label,
									) ? (
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 32 32"
												width="32"
												height="32"
											>
												<path
													fill="#595959"
													stroke="#595959"
													strokeWidth="2.0382"
													strokeMiterlimit="10"
													d="M25,25.5H7c-1.1,0-2-0.9-2-2v-15
											c0-1.1,0.9-2,2-2h18c1.1,0,2,0.9,2,2v15C27,24.6,26.1,25.5,25,25.5z"
												/>
												<polyline
													fill="none"
													stroke="#FFFFFF"
													strokeWidth="1.5"
													strokeMiterlimit="10"
													points="12,16.4 16,18.5 20,11.5 "
												/>
											</svg>
										) : (
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 32 32"
												width="32"
												height="32"
											>
												<path
													fill="none"
													stroke="#595959"
													strokeWidth="2.0382"
													strokeMiterlimit="10"
													d="M25,25.5H7c-1.1,0-2-0.9-2-2v-15
												c0-1.1,0.9-2,2-2h18c1.1,0,2,0.9,2,2v15C27,24.6,26.1,25.5,25,25.5z"
												/>
											</svg>
										)}
								</li>
							)
						})}
					</ul>
				</div>
			</div>
		)
	}
}

const GraduatesListGenerator = () => (
	<StaticQuery
		query={graphql`
			query {
				allMarkdownRemark(
					sort: { order: ASC, fields: [frontmatter___name] }
					filter: { fileAbsolutePath: { regex: "/graduates/" } }
				) {
					edges {
						node {
							id
							excerpt(pruneLength: 250)
							frontmatter {
								path
								name
								cover {
									childImageSharp {
										fluid(maxWidth: 480, quality: 70) {
											...GatsbyImageSharpFluid
										}
									}
								}
								portfolio
								linkedin
								twitter
								instagram
								medium
								dribble
								behance
								interestArea
								where
								relocation
								field
							}
						}
					}
				}
			}
		`}
		render={data => <GraduatesList data={data} />}
	/>
)

export default GraduatesListGenerator

GraduatesList.propTypes = {
	data: PropTypes.any,
	node: PropTypes.object,
	id: PropTypes.string,
	excerpt: PropTypes.string,
	frontmatter: PropTypes.shape({
		path: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		cover: PropTypes.any,
		portfolio: PropTypes.string,
		linkedin: PropTypes.string,
		twitter: PropTypes.string,
		instagram: PropTypes.string,
		medium: PropTypes.string,
		behance: PropTypes.string,
		interestArea: PropTypes.string,
		where: PropTypes.string,
		relocation: PropTypes.string,
		field: PropTypes.string,
	}),
}
