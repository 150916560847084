import React from 'react'

import Layout from '../components/layout'
import GraduatesList from '../components/GraduatesList'
import Banner from '../components/Banner'

const GraduatesPage = () => (
	<Layout>
		<GraduatesList />
		<Banner
			title="Like What You See?"
			subtitle="Meet the grads behind these projects In Real Life at the grad show on April 10 & 11 at Free Space!"
			cta="View Details"
		/>
	</Layout>
)

export default GraduatesPage
